
// DEV https://portal.dev.rsedigitalservices.se/

export const environment = {
  production: true,
  localhost: false,
  project: 'rsedt-mgmt-portal',
  application: 'RSEDT-Portal',
  title: 'Portal',
  disableLogging: false, // only affects logging done through LoggerService.
  alertDuration: 5000,
  blastManagerUrl: 'https://login-test.infobricblastmanager.no/Project_MaasRedirect.aspx',
  API_BASE_URL: '/api',
  API_BASE_CLAIM: 'api://45e16a06-b787-4111-a976-0ac1a6080541/API.ReadWrite.All',
  OAUTH_KEYS_URL: 'https://login.microsoftonline.com/common/discovery/v2.0/keys',
  OAUTH_AUTHORITY: 'https://login.microsoftonline.com/c1b3b4ec-fc91-4904-a2c4-54d649994802/',
  OAUTH_ISSUER: 'https://login.microsoftonline.com/c1b3b4ec-fc91-4904-a2c4-54d649994802/v2.0',
  OAUTH_CLIENTID: '45e16a06-b787-4111-a976-0ac1a6080541',
  OAUTH_REDIRECT_URL: 'https://portal.maas-uat.ramboll.cloud/',
  OAUTH_GRAPH_URL: 'https://graph.microsoft.com/v1.0/',
  MSAL_LOGGING: false,
  AppInsightsCS: 'InstrumentationKey=27368d03-ea89-462f-afb6-deb9819867d5;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
  ENABLE_PDF_EXORT: true,
};
